import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { faChevronLeft, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { ZoomService } from '../../../../../../../../../../../../shared/services/transactional/zoom/zoom.service';
import { finalize } from 'rxjs';
import { SpinnerService } from '../../../../../../../../../../../../shared/services/shared/spinner/spinner.service';
import { ToastService } from '../../../../../../../../../../../../shared/services/shared/toast/toast.service';

@Component({
    selector: 'app-zoom-success',
    templateUrl: './zoom-success.component.html',
    styleUrl: './zoom-success.component.scss',
    standalone: false
})
export class ZoomSuccessComponent implements OnInit {

  public faChevronLeft: IconDefinition = faChevronLeft;

  constructor(
    private _route: ActivatedRoute,
    private _zoomService: ZoomService,
    private _spinerService: SpinnerService,
    private _toastService: ToastService
  ) { }

  public ngOnInit(): void {
    let state: string = this._route.snapshot.queryParams["state"];
    let userId = state.split(" ")[0];
    let organizationId = state.split(" ")[1];

    let code: string = this._route.snapshot.queryParams["code"];
    if (!state || !userId || !organizationId || !code) {
      return;
    }
    this._spinerService.show("");

    this._zoomService.updateZoomCode(userId, organizationId, code)
      .pipe(
        finalize(() => this._spinerService.hide())
      )
      .subscribe(
        {
          next: response => {
            if (response.isSuccess) {
              if (response.data) {
                this._toastService.showSuccess("Zoom is integrated with app");
                setTimeout(() => {
                  window.close();
                }, 1000);
              }
            }
          }
        }
      );
  }
}
