import { ContributorDetailComponent } from './../../../../modules/scheduler/modules/organizations/modules/organization/modules/app-settings/components/contibutors/contributor-detail/contributor-detail.component';
import { Injectable } from '@angular/core';
import { TransactionalService } from '../transactional.service';
import { Observable } from 'rxjs';
import { IRescheduleAppointment } from '../../../models/dtos/update/reschedule-appointment.interface';
import { IResponse } from '../../../models/dtos/response.interface';
import { HttpParams } from '@angular/common/http';
import { IZoomOrganizationAppointmentType } from '../../../models/dtos/get/zoom-organization-appointment-type.interface';
import { IZoomOrganization } from '../../../models/dtos/get/zoom-organization.interfaqce';

@Injectable({
  providedIn: 'root'
})
export class ZoomService {

  constructor(private _transactionalService: TransactionalService) { }

  public getZoomClientId(): Observable<IResponse<string>> {
    return this._transactionalService.get<IResponse<string>>("get-zoom-client-id");
  }

  public isZoomAccountIntegrated(organizationId: string): Observable<IResponse<IZoomOrganization>> {
    let params = new HttpParams().set("organizationId", organizationId);
    return this._transactionalService.get<IResponse<IZoomOrganization>>("is-zoom-account-integrated?" + params.toString());
  }

  public updateZoomCode(userId: string, organizationId: string, code: string): Observable<IResponse<string>> {
    let params: HttpParams = new HttpParams()
      .set('userId', userId)
      .set("organizationId", organizationId)
      .set("code", code);

    return this._transactionalService.get<IResponse<string>>("update-zoom-code?" + params.toString());
  }

  public updateZoomOrganizationAppointmentTYpe(zoomOrganizationId: number, updateZoomOrganizationAppointmentTypes: IZoomOrganizationAppointmentType[]): Observable<IResponse<boolean>> {
    let params: HttpParams = new HttpParams()
      .set('zoomOrganizationId', zoomOrganizationId);

    return this._transactionalService.add<IZoomOrganizationAppointmentType[], IResponse<boolean>>(updateZoomOrganizationAppointmentTypes, "update-zoom-organization-appointment-type?" + params.toString());
  }

  public revoke(organizationId: string): Observable<IResponse<string>> {
    let params: HttpParams = new HttpParams()
      .set("organizationId", organizationId);

    return this._transactionalService.get<IResponse<string>>("revoke-zoom-app?" + params.toString());
  }
}
